import React, { createContext, useContext, useState, useEffect } from "react";
import {useLocation} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import {useConfig} from './ConfigProvider';

import Loader from './Loader';
import Api from './baseApi';

export const Context = createContext(null);
export const useTunnelData = () => useContext(Context);

function pushEvent(e) {
	if (!window.onairEvent)
		return;
	
	if( !window.dataLayer ) {
		console.warn("Cannot push event", e);
		return false;
	}
	window.dataLayer.push(e);
	console.debug("Push event", e)
}

const TunnelContext = ({ children }) => {
	const [step, stepSet] = useState("INIT");
	const {search} = useLocation();
	const config = useConfig();
	const trackerUid =uuidv4().replace(/-/gi, '');
	const uid = `${window.origin}::${Date.now()}::${trackerUid}`;

	const [ tunnel, updateTunnel ] = useState({
		uid,
		trackerUid,
		notifyChannel: config.notifyChannel
	});

	function setTunnel() {
		updateTunnel.apply(this, arguments);
	}

	window.tunnel = tunnel;

	const setTunnelClub = club => {
		const newTunnel = {...tunnel};

		if ( !club || club.msdsId !== tunnel.club?.msdsId ) {
			delete newTunnel.products;
			delete newTunnel.contactResa;
			delete newTunnel.offer;
			newTunnel.options = [];
			newTunnel.regulars = {};
			delete newTunnel.promo;
		}

		newTunnel.club = club;
		setTunnel(newTunnel);
	};

	useEffect( () => {
		if( tunnel?.club?.fbq && window.fbq ) {
			console.debug("Init fbPixel with", tunnel.club.fbq)
			window.fbq('init', tunnel.club.fbq);
			window.fbq('track', 'PageView');
		}
	}, [tunnel?.club?.msdsId]);

	const updateTunnelUser = changes => {
		const user = tunnel.user || {};
		Object.entries(changes).forEach( ([k,v]) => {
			user[k] = v;
		})
		user.hasChanged = true;

		const newTunnel = {...tunnel, user};
		delete newTunnel.contactResa;

		setTunnel(newTunnel)
	};

	const updateTunnelContactResa = contactResa => {
		const newUser = {...tunnel.user};
		newUser.hasChanged = false;

		const newTunnel = {...tunnel, user: newUser, contactResa};
		setTunnel(newTunnel);
	};

	const setTunnelOffer = offer => {
		const newTunnel = {...tunnel, offer};
		const mandatoriesOptions = offer.productBehaviors
		.map( b => b?.configuration?.linkedOptions || [])
		.flat()
		.map(productId => {
			const found = tunnel.products.options.find( o => o.offer.product === productId);
			if( !found ) {
				console.error("Cannot found mandatory option with productId", productId)
			}
			//Do not change the option directly please
			return {...found, mandatory: true};
		}).filter( e => e);
		newTunnel.options = mandatoriesOptions;
		newTunnel.regulars = {};
		delete newTunnel.promo;
		setTunnel(newTunnel);

		pushEvent({
			'event' : 'add_to_cart',
			'currency' : 'EUR',
			'value' : offer.offer.registrationFeeTE,
			'items': [{
				item_id: offer['@id'],
				item_name: offer.name,
				price : offer.offer.registrationFeeTE,
				price_tax_exc : offer.offer.registrationFeeTE,
				price_tax_inc : offer.offer.registrationFeeTI
			}]
		});
	}

	const setTunnelOptions = options => {
		const newTunnel = {...tunnel, options};
		setTunnel(newTunnel);
	}

	const setTunnelRegulars = regulars => {
		const newTunnel = {...tunnel, regulars};
		setTunnel(newTunnel);
	}

	const setTunnelUtm = (utm_source, utm_medium, utm_campaign) => {
		const newTunnel = {...tunnel, utm_source, utm_medium, utm_campaign};
		setTunnel(newTunnel);
	}

	//withContact cause updateContext is async
	const createSale = (withContact) => {
		const { club, contactResa, offer, options, regulars } = tunnel;
		const [ query ] = Api.POST({
			path: '/api/abo/resamania2/sales',
			body: {
				tunnelUid: tunnel.uid,
				msdsId: club.msdsId,
				contact: {
					'@id': (withContact || contactResa)['@id'],
					contactNumber: (withContact || contactResa).number,
					contactFamilyName: (withContact || contactResa).familyName,
					contactGivenName: (withContact || contactResa).givenName,
					address: (withContact || contactResa).address
				},
				offer: offer,
				options: options,
				regulars: regulars,
				promo: tunnel.promo
			}
		});

		return query
		.then(ret => {
			setTunnel({ ...tunnel, sales: ret.sale, contactResa: tunnel.contactResa || withContact });
		})
	};

	useEffect( () => {
		if( step !== 'INIT' )
			return;

		const tunnelId = new URLSearchParams(search).get('tunnelId');
		if( tunnelId ) {
			stepSet('LOADING_PAYMENT')
			const [ query ] = Api.GET({
				path: '/api/abo/tunnel',
				query: {
					tunnelId: tunnelId
				}
			});

			query
			.then(ret => {
				setTunnel(ret);
				stepSet('OK')
			})
			.catch( e => {
				stepSet("ERROR")
			})
			return;
		}

		try {
			const user = JSON.parse(localStorage.getItem('user'));
			if( user) {
				setTunnel({...tunnel, user});
			}
			stepSet('OK')
			return;
		} catch(e) {
			stepSet('OK')
			//Don't care
		}
	}, []);


	if( step === "INIT") {
		return null;
	}

	if( step === "ERROR") {
		return <p style={{margin:'auto'}}>Une erreur est survenue</p>
	}

	if( step === "LOADING_PAYMENT") {
		//TODO
		return <Loader text="Vérification de votre payment"/>;
	}

	return (
		<Context.Provider value={{tunnel, setTunnel, setTunnelClub, updateTunnelUser, updateTunnelContactResa, setTunnelOffer, setTunnelOptions, setTunnelRegulars, setTunnelUtm, createSale}}>
			{children}
		</Context.Provider>
	);
};

export default TunnelContext;
