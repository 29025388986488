import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";

import {useConfig} from '../ConfigProvider';
import { useTunnelData } from "../TunnelContext";
import StepHeader from "../StepHeader";
import Button from "../Button";
import Api from "../baseApi";
import Step from './Step';
import StyledStepFooter from '../StyledStepFooter';

import './Sepa.scss';

function pushEvent(e) {
  if (!window.onairEvent)
    return;
  
  if( !window.dataLayer ) {
    console.warn("Cannot push event", e);
    return false;
  }
  window.dataLayer.push(e);
  console.debug("Push event", e)
}

export default function Sepa(){
  const config = useConfig();
  const [ step, setStep ] = useState("FORM");
  const { tunnel, setTunnel } = useTunnelData();
  const [error, errorSet] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    pushEvent({
      'event' : 'funnel_step_4',
      'step_name' : 'Abonnement',
      'funnel_club' : tunnel.club?.name,
      //TODO
      'funnel_abo' : tunnel.offer?.name,
      'funnel_club_status' : 'Ouvert',
    });

  }, []);

  if (!tunnel.club || !tunnel.user || !tunnel.offer || !tunnel.options) return <Redirect push to={'/'} />;

  const hasRepaymentSchedule = (p) => {
    const isBundle = (p.productBehaviors[0]?.behavior.endsWith("/behaviors/bundle"));
    if( isBundle )
      return true;

    if(!p.offer.repaymentSchedule){
      return false;
    }

    if( p.offer.repaymentSchedule instanceof Array )
      return !!p.offer.repaymentSchedule.length;

    return !!Object.keys(p.offer.repaymentSchedule).length;
  }

  let needSepa = hasRepaymentSchedule(tunnel.offer)
  tunnel.options.forEach( o => {
    needSepa = needSepa || hasRepaymentSchedule(o);
  })
  Object.values(tunnel.regulars).forEach( reg => {
    needSepa = needSepa || hasRepaymentSchedule(reg.regular);
  })

  if( !needSepa ) {
     return <Redirect push to={'/card?direct'} />;
  }

  const handleChange = (ev) => {
    const {name, value} = ev.target;
    errorSet(false);
    setTunnel({ ...tunnel, payment: { ...tunnel.payment, [name]: value }});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    errorSet(false);
    setStep("CREATE");
    const { contactResa, payment } = tunnel;

    const [ query ] = Api.POST({
      path: '/api/abo/resamania2/mandates',
      body: {
        tunnelUid: tunnel.uid,
        msdsId: tunnel.club.msdsId,
        mandate: {
          clubId: contactResa.clubId,
          contactId: contactResa['@id'],
          contactFamilyName: contactResa.familyName,
          contactGivenName: contactResa.givenName,
          contactNumber: contactResa.number,
          contactAddress: {
            addressCountry: contactResa.address.addressCountry,
            addressLocality: contactResa.address.addressLocality,
            postalCode: contactResa.address.postalCode,
            streetAddress: contactResa.address.streetAddress,
          },
          holder: payment.holder,
          iban: payment.iban,
          bic: payment.bic,
          bank: payment.bank,
        }
      }
    });

    query.then(mandate => {
      setTunnel({ ...tunnel, mandate });
      setStep("CONTRACT");
    })
    .catch( e => {
      console.error(e);
      setStep("FORM")
      errorSet(e.userMsg || "Une erreur est survenue");
    });
  };


  const isValid = () => {
    const p = tunnel.payment;
    if(!p)
      return false;

    if( !p.holder || !p.iban || (!p.bic && !config.sepa.noBic) || !p.bank)
      return false;

    //TODO more validation here please

    return true;
  }

  if (step === "CONTRACT")
    return <Redirect push to={'/contract'} />;

  if (step === "CREATE")
    return (
      <Step classe="Sepa" step="Contrat" loading={config.loadingTextContractCreation}/>
    );

  return (
    <Step classe="Sepa" step="Contrat">
      <header>
        <StepHeader text={config.sepa.title}/>
      </header>

      <form onSubmit={onSubmit}>
        <em style={{marginBottom:'1rem'}}>{config.sepa.explain}</em>
        <fieldset>
          <label>
            <input onChange={handleChange} name={"holder"} type="text" placeholder={config.sepa.ownerLabel} value={tunnel.payment?.holder || ''}/>
          </label>
          <label>
            <input onChange={handleChange} name={"iban"} type="text" placeholder={config.sepa.ibanLabel} value={tunnel.payment?.iban || ''}/>
          </label>
          {!config.sepa.noBic && 
            <label>
              <input onChange={handleChange} name={"bic"} type="text" placeholder={config.sepa.bicLabel} value={tunnel.payment?.bic || ''}/>
            </label>
          }
          <label>
            <input onChange={handleChange} name={"bank"} type="text" placeholder={config.sepa.bankLabel} value={tunnel.payment?.bank || ''}/>
          </label>
        </fieldset>
      </form>

      <StyledStepFooter>
        {!!error && <p style={{textAlign:'center', fontWeight:'bold'}}>{error}</p>}
        <Button style={{textAlign:'center'}} variant='primary' type='submit' onClick={onSubmit} disabled={!isValid()}>
          {config.sepa.validateLabel}
        </Button>
      </StyledStepFooter>
    </Step>
  );
};
