import styled from 'styled-components';
import devices from './devices';
import {useEffect} from 'react';
import {useConfig} from './ConfigProvider';

export default function Confirm({checked, toggle, className, children}){
	const config = useConfig();

	return (
		<StyledConfirm theme={config.theme} checked={checked} className={className}>
			<input type="checkbox" name="confirmClub" checked={checked} onChange={toggle}/>
			{children}
		</StyledConfirm>
	);
}

const StyledConfirm = styled.label`
	align-items: flex-start;
	flex-direction: row;
	cursor: pointer;
	align-items: flex-start;
	margin-bottom: 1rem;
	flex-direction: row;
	cursor: pointer;

	@media(${devices.tablet.query}) {
		margin-top: 1rem;
	}
	em {
		font-weight: 300;
		font-size: .875rem;
		line-height: 1.15rem;
		font-style: italic;
	}
	input {
		margin-right: .5rem;
		margin-top: .15rem;
		width: 1rem;
		height: 1rem;
		flex-shrink: 0;
		border: 1px solid #000;
		${(props) => {
			if( props.checked) {
				return `
					background-color: ${props.theme.primary};
					&::before {
						content: "";
						width: 100%;
						height: 100%;
						border: 2px solid white;
						box-sizing: border-box;
					}
				`
		}}
	}
}
`