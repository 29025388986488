import React, {useEffect, useState} from "react";
import {useConfig} from '../ConfigProvider';
import {useTunnelData} from "../TunnelContext";
import Button from "../Button";
import StepHeader from "../StepHeader";
import Step from './Step';
import Choice from '../Choice';
import StyledStepFooter from '../StyledStepFooter';

import "./Plans.scss";

function pushEvent(e) {
  if (!window.onairEvent)
    return;
  
  if( !window.dataLayer ) {
    console.warn("Cannot push event", e);
    return false;
  }
  window.dataLayer.push(e);
  console.debug("Push event", e)
}


export default function OfferSelect({plans, goNext, errorMsg, errorMsgSet}){
  const config = useConfig();
  const {tunnel, setTunnelOffer} = useTunnelData();
  let initialType = '*';
  if( config.plans.splitCounterAbo ) {
    initialType = 'subscription';
    if( tunnel.offer ) {
      initialType = tunnel.offer.type;
    }
  }
  const [type, typeSet] = useState(initialType);

  useEffect( () => {
    window.scrollTo(0, 0);
    pushEvent({
      'event' : 'funnel_step_2',
      'step_name' : 'Abonnement',
      'funnel_club' : tunnel.club?.name,
      //TODO
      'funnel_club_status' : 'Ouvert',
    });
  }, []);

  const select = (offer) => {
    errorMsgSet(false);
    setTunnelOffer(offer);
    if( config.plans.autoSubmit ) {
      goNext();
    }
  };

  let className = 'OptionsList';
  if( config.options.desktopInlineBy3 ) {
    className += ' desktopInlineBy3';
  }

  return (
    <Step classe="Plans" step="Formules d'abonnement & options">
      <header>
        <StepHeader text={config.plans.title}/>
      </header>
      { type !== '*' && (
        <div className='typeFilter' style={{display:'flex', justifyContent:'space-around', marginTop:'2rem'}}>
          <Button variant={type==='subscription' ? 'primary': 'textOnly'} onClick={()=>typeSet('subscription')} style={{flexDirection:'column', textAlign:'center', padding:'1rem'}}>
            {config.plans.splitCounterAbo.subsText.map( (t,i) => <span key={i}>{t}</span>)}
          </Button>
          <Button variant={type==='counter' ? 'primary': 'textOnly'} onClick={()=>typeSet('counter')} style={{flexDirection:'column', textAlign:'center', padding:'1rem'}}>
            {config.plans.splitCounterAbo.passText.map( (t,i) => <span key={i}>{t}</span>)}
          </Button>
        </div>
      )}
      <ul className={className}>
        {plans.filter(o=> type === '*' || o.type === type).map((option, i) =>
          <li key={`option_${i}`}>
            <Choice value={option} selected={[tunnel.offer].filter(e=>e)} onSelect={select} useImage={config.plans.useImg} imageBaseURL={config.plans.imageBaseURL}/>
          </li>
        )}
      </ul>
      <StyledStepFooter>
        {!tunnel.offer && (
          <span style={{marginBottom:'0.5rem'}}>{config.plans.incitation}</span>
        )}
        { (tunnel.offer && !!tunnel.offer.offer.registrationFeeTI) && (
          <span style={{marginBottom:'0.5rem', flexDirection:'row', gap:'1rem'}}>
          {config.plans.feesLabel}
          :
            <strong>{(tunnel.offer.offer.registrationFeeTI / 100).toFixed(2)} €</strong>
          </span>
        )}
        { (tunnel.offer && !tunnel.offer.offer.registrationFeeTI) && (
            <span style={{marginBottom:'0.5rem'}}>{config.plans.nofeesLabel}</span>
        )}
        {!!errorMsg && <p style={{textAlign:'center', fontWeight:'bold'}}>{errorMsg}</p>}
        <Button variant='primary' disabled={!tunnel.offer} onClick={goNext}>{config.plans.nextLabel}</Button>
      </StyledStepFooter>
    </Step>
  );
}
