import {useEffect} from 'react';
import "./Confirmation.scss";
import {useConfig} from '../ConfigProvider';
import StyledStepFooter from '../StyledStepFooter';
import {useTunnelData} from "../TunnelContext";

function pushEvent(e) {
  if (!window.onairEvent)
    return;

  if( !window.dataLayer ) {
    console.warn("Cannot push event", e);
    return false;
  }
  window.dataLayer.push(e);
  console.debug("Push event", e)
}

function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  return window.crypto.subtle.digest("SHA-256", msgUint8) // hash the message
  .then( hashBuffer => {
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // convert bytes to hex string
    return hashHex;
  })
}

export default function Confirmation(){
  const {tunnel} = useTunnelData();
  const config = useConfig();

  useEffect(() => {
    window.scrollTo(0, 0)
    if( tunnel.club?.fbq && window.fbq) {
      window.fbq('track', 'PageView');
    }
    if( !tunnel.sales ) {
      return;
    }
    try {
      Promise.all([
        digestMessage(tunnel.user.email.trim().toLowerCase()),
        digestMessage(tunnel.user.phone.replace(/[^0-9]/g, '')),
        digestMessage(tunnel.user.firstName.trim().toLowerCase()),
        digestMessage(tunnel.user.lastName.trim().toLowerCase()),
        digestMessage(tunnel.user.zipcode.replace(/[^0-9]/g, '')),
        digestMessage(tunnel.user.city.trim().toLowerCase()),
      ])
      .then( ([em, ph, fn, ln, zp, ct]) => {
        pushEvent({
          event: 'funnel_step_final',
          step_name: 'Abonnement',
          funnel_club: tunnel.club.name,
          //TODO
          funnel_club_status: 'Ouvert',
          funnel_abo: tunnel.offer.offer.name,
          em, ph, fn, ln, zp, ct
        });
      })
    } catch( e ) {
      console.error(e);
    }
    pushEvent({
      event: 'purchase',
      //TODO
      // 'transaction_id: '1723804',
      currency: 'EUR',
      value: tunnel.sales.totalTI,
      tax: tunnel.sales.totalTI - tunnel.sales.totalTE,
      'items': [{
        item_id: tunnel.offer['@id'],
        item_name: tunnel.offer.name,
        price : tunnel.offer.offer.registrationFeeTE,
        price_tax_exc : tunnel.offer.offer.registrationFeeTE,
        price_tax_inc : tunnel.offer.offer.registrationFeeTI
      }]
    });
  }, []);

  return (
    <main className="Confirmation">
      {config.confirmation.tracker && config.confirmation.tracker(tunnel) }
      <section>
        <h2>{config.confirmation.welcomeLabel} {config.companyName} !</h2>
        <img src="/icons/check.svg" alt="Success"/>
        <p>{config.confirmation.text}</p>
      </section>
      <StyledStepFooter>
        <a style={{
          background: config.theme.primary,
          color: "white",
          textAlign: "center",
          fontWeight: "bold",
          padding:'0.5rem'
        }}
        href={config.website}>{config.confirmation.backLabel}</a>
      </StyledStepFooter>
    </main>
  );
}




